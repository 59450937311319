import 'focus-within-polyfill';

import './global/jquery-migrate';
import './common/select-option-plugin';
import PageManager from './page-manager';
import quickSearch from './global/quick-search';
import currencySelector from './global/currency-selector';
import mobileMenuToggle from './global/mobile-menu-toggle';
import menu from './global/menu';
import foundation from './global/foundation';
// TSdev nd 2023-10-25 removed import of quick-view.js which we have deleted
import cartPreview from './global/cart-preview';
import privacyCookieNotification from './global/cookieNotification';
import carousel from './common/carousel';
import SiteWideBanner from 'bigcommerce-sitewide-banners';
import svgInjector from './global/svg-injector';

// OB Custom
// import initNavMenu from "./custom/nav-menu";

export default class Global extends PageManager {
    onReady() {
        // OB Custom
        // initNavMenu();
      
        const { cartId, secureBaseUrl } = this.context;
        cartPreview(secureBaseUrl, cartId);
        quickSearch();
        currencySelector(cartId);
        foundation($(document));
      // TSdev nd 2023-10-25 removed quick-view.js which we have deleted
        carousel(this.context);
        menu();
        mobileMenuToggle();
        privacyCookieNotification();
        svgInjector();

        const swb = new SiteWideBanner();
        swb.getBanners()
        .then(banners => {
            // On dashboard selected as Top of page
            swb.addBanners({ banners: [banners.top[0]] });
        })
        .catch(error => console.error(error));
    }
}
